import { AssetType } from '@/modules/asset/constants';

export function useAsset() {
    // #TODO: better rename this file to asset-type.ts
    const assetTypeName = (assetTypeId: number) => {
        switch (assetTypeId) {
            case 1:
                return AssetType.Dataset;
            case 2:
                return AssetType.Result;
            case 3:
                return AssetType.Model;
            default:
                return AssetType.Dataset;
        }
    };

    const assetTypeClass = {
        1: 'bg-orange-100 text-orange-800',
        2: 'bg-indigo-100 text-indigo-800',
        3: 'bg-purple-100 text-purple-800',
    };

    return { assetTypeName, assetTypeClass };
}
