import { useSockets } from '@/app/composable/socket';
import { S } from '@/app/utilities';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { ExecutionStatusWrapper, ExecutionType, MessageType } from '@/modules/workflow-designer/constants';
import { EventMessage } from '@/modules/workflow-designer/types';
import { Ref, computed, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { accessLevelOptions, licenseOptions, licenses } from '../config/asset';
import { KerasStructure, MLLIbStructure, PMDArima, SKLearnStructure } from '../constants';
import { ModelAlgorithmLibrary } from '../types/model-algorithm-library.type';

export function useModelRegistration(asset: Ref<any>, readOnly: Ref<boolean> = ref(false)) {
    const accessLevel = ref<any>(null);
    const copyrightOwner = ref<any>(null);
    const {
        subscribe,
        unsubscribe,
        WebSocketsEvents,
        joinSocketRoom,
        leaveSocketRoom,
        WebSocketsRoomTypes,
    } = useSockets();

    const checkLicense = (license: any) => {
        if (license && license.label !== 'Custom') {
            const licenseMetadata = licenses.find((element) => element.license === license.label);
            if (licenseMetadata) {
                asset.value.metadata.license.license = licenseMetadata.license;
                asset.value.metadata.license.link = licenseMetadata.link;
            }
        } else if (license && license.label === 'Custom') {
            asset.value.metadata.license.license = 'Custom';
            asset.value.metadata.license.link = null;
        }
    };

    const customLicense = computed(() => {
        if (
            asset.value &&
            asset.value.metadata &&
            asset.value.metadata.license &&
            (asset.value.metadata.license.license === undefined ||
                asset.value.metadata.license.license === null ||
                asset.value.metadata.license.license === 'Custom')
        ) {
            return true;
        }
        return false;
    });

    const getLicensingSchema = computed(() => {
        const schema: any = [];
        let items: any = null;
        if (asset.value.metadata.license) {
            items = [
                {
                    type: 'treeselect',
                    name: 'license',
                    label: 'Licence',
                    placeholder: 'Select licence',
                    clearable: false,
                    disableBranchNodes: true,
                    options: licenseOptions,
                    help:
                        'The legal statement/terms giving official permission to the asset in a custom manner or according to well-defined data licences.',
                    helpPosition: 'before',
                    validation: 'required',
                    labelClass: ['pb-1'],
                    disabled: accessLevel.value === AccessLevel.SelectiveSharing || readOnly.value,
                    input: () => {
                        //
                    },
                    select: checkLicense,
                    errorBehavior: 'value',
                },
                {
                    type: 'text',
                    name: 'link',
                    label: 'Link',
                    placeholder: 'Enter link',
                    help: 'A link to the exact legal terms of the specific licence.',
                    helpPosition: 'before',
                    validation: asset.value.metadata.license.link
                        ? 'bail|url|min:5,length|max:300,length|matches:/^[a-zA-Z0-9.:/-]+$/'
                        : null,
                    validationMessages: {
                        matches: 'Link can only contain letters, numbers, dots, dashes, slashes and colons.',
                    },
                    disabled: !customLicense.value || readOnly.value,
                    inputClass: 'form-input',
                    labelClass: ['pb-1'],
                    errorBehavior: 'value',
                },
            ];
            Array.prototype.push.apply(schema, items);
        }
        return schema;
    });

    const getGeneralSchema = computed(() => {
        const items: any[] = [
            {
                type: 'multiselect',
                name: 'tags',
                label: 'Tags',
                placeholder: 'Add tags',
                options: [],
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                preserveSearch: true,
                preselectFirst: false,
                taggable: true,
                help:
                    'A list of keywords and/or arbitrary textual tags associated with the asset by its data provider.',
                helpPosition: 'before',
                validation: 'bail|required|max:10|minTag:2|maxTag:50|alphanumericTag',
                validationRules: {
                    minTag: ({ value }: any) => value.every((tag: any) => tag.length >= 2),
                    maxTag: ({ value }: any) => value.every((tag: any) => tag.length <= 50),
                    alphanumericTag: ({ value }: any) =>
                        value.every((tag: any) => new RegExp(/^[a-zA-Z0-9 ]+$/).test(tag)),
                },
                validationMessages: {
                    minTag: 'Tags must be at least 2 characters long.',
                    maxTag: 'Tags must be less than or equal to 50 characters long.',
                    alphanumericTag: 'Tags can only contain letters, numbers and spaces.',
                },
                labelClass: ['pb-1'],
                errorBehavior: 'value',
            },
        ];

        return items;
    });

    const initAsset = (blocks: { general: boolean; extent: boolean; licensing: boolean; model: boolean }) => {
        const emptyAsset: any = {
            name: null,
            description: null,
            status: null,
            metadata: {},
            policies: [],
            assetTypeId: 3,
        };

        if (blocks.general) {
            emptyAsset.metadata.general = {
                tags: [],
            };
        }

        if (blocks.licensing) {
            emptyAsset.metadata.license = {
                accessLevel: null,
                license: null,
                copyrightOwner: null,
                link: null,
            };
        }

        if (blocks.model) {
            emptyAsset.metadata.model = {
                library: '',
                type: '',
                purpose: '',
                name: '',
                algorithm: '',
                featureOrder: [],
                encodedFeatures: [],
            };
        }
        return emptyAsset;
    };

    const modelStructure = ref<any>();

    const showModelValidationModal = ref<boolean>(false);
    const modelValidationStatus = ref<string>('');
    const modelValidationMessage = ref<string>();
    const canCloseModal = ref<boolean>(false);

    const librarySelected = async (event: any) => {
        switch (event) {
            case 'keras':
                modelStructure.value = KerasStructure;
                break;
            case 'mllib':
                modelStructure.value = MLLIbStructure;
                break;
            case 'sklearn':
            case 'xgboost':
            case 'statsmodel':
                modelStructure.value = SKLearnStructure;
                break;
            case 'pmdarima':
                modelStructure.value = PMDArima;
                break;
            default:
                modelStructure.value = [];
                break;
        }
    };

    const modalImage = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return '/img/file_sync.svg';
            case 'running':
                return '/img/validating.svg';
            case 'completed':
                return '/img/success.svg';
            case 'failed':
            case 'invalid':
                return '/img/fail.svg';
            default:
                return '/img/validating.svg';
        }
    });

    const modalTitle = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return 'Uploading model and sample data for validation';
            case 'running':
                return 'Validating model using sample data';
            case 'completed':
                return 'Model Validation Success!';
            case 'failed':
            case 'invalid':
                return 'Model Validation Failed!';
            default:
                return 'Validating model using sample data';
        }
    });

    const modalDescription = computed(() => {
        switch (modelValidationStatus.value) {
            case 'uploading':
                return 'Uploading model and sample data for validation. If the model provided is large, this may take a while. The sample file is cropped to the first 50 rows.';
            case 'running':
                return 'Model and sample data successfully uploaded. The model is now being validated by applying it to the sample data provided.';
            case 'completed':
                return 'Your model is successfully validated. You can fill-in the remaining forms and complete the registration!';
            case 'failed':
                return 'Your model could not be validated. Make sure you have filled in the correct model details, including model library, type, purspose and the model features in the order they were trained.';
            case 'invalid':
                return 'Your model directory structure is invalid. Make sure you follow the specified directory structure before zipping your model.';

            default:
                return 'Validating model using sample data';
        }
    });

    const modelSourceOptions = {
        Upload: 'Upload new trained model',
        Platform: 'Trained on the platform',
        OnPremise: 'Trained on-premise',
    };

    const modelLibraryOptions: Record<ModelAlgorithmLibrary, string> = {
        mllib: 'MLlib',
        sklearn: 'Scikit-learn',
        xgboost: 'XGBoost',
        statsmodel: 'Statsmodel',
        keras: 'Keras (Tensorflow)',
        pmdarima: 'Pmdarima',
    };

    const modelTypeOptions = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.model) {
            if (asset.value.metadata.model.library === 'sklearn' || asset.value.metadata.model.library === 'mllib') {
                return {
                    model: 'Model',
                    transformer: 'Transformer (encoder/scaler)',
                    pipeline: 'Pipeline (transformers & model)',
                };
            }
            if (asset.value.metadata.model.library !== '') {
                return { model: 'Model' };
            }
        }
        return {};
    });

    const modelPurposeOptionsObject = {
        modelOrPipeline: {
            mllib: {
                classification: 'Classification',
                regression: 'Regression',
                clustering: 'Clustering',
            },
            sklearn: {
                classification: 'Classification',
                regression: 'Regression',
                clustering: 'Clustering',
                'unsupervised outlier detection': 'Outlier Detection',
            },
            xgboost: {
                classification: 'Classification',
                regression: 'Regression',
            },
            statsmodel: {
                'timeseries forecasting': 'Timeseries Analysis (arima, sarimax)',
                'timeseries forecasting var': 'Timeseries Analysis (VAR)',
            },
            keras: {
                'deep learning': 'Deep Learning',
            },
            pmdarima: {
                'timeseries forecasting': 'Timeseries Analysis',
            },
        },
        transformer: {
            mllib: {
                'One Hot Encoding': 'One Hot Encoding',
                'String Indexing': 'String Indexing',
                'MinMax Scaling': 'MinMax Scaling',
                'Standard Scaling': 'Standard Scaling',
                'Dimensionality Reduction': 'Dimensionality Reduction',
            },
            sklearn: {
                'One Hot Encoding': 'One Hot Encoding',
                'Label Encoding': 'Label Encoding',
                'Ordinal Encoding': 'Ordinal Encoding',
                'MinMax Scaling': 'MinMax Scaling',
                'Standard Scaling': 'Standard Scaling',
                'Dimensionality Reduction': 'Dimensionality Reduction',
            },
        },
    };

    const modelPurposeOptions = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.model) {
            const type = asset.value.metadata.model.type === 'transformer' ? 'transformer' : 'modelOrPipeline';
            if (S.has(asset.value.metadata.model.library, modelPurposeOptionsObject[type])) {
                return modelPurposeOptionsObject[type][asset.value.metadata.model.library];
            }
        }
        return {};
    });

    const runningExecution = ref<string>();

    const onMessage = async (data: EventMessage) => {
        // Handle the case where we have an execution status change
        if (
            data.type === MessageType.Status &&
            Object.values(ExecutionType).includes(data.body.executionType as ExecutionType)
        ) {
            // Figure out if the currently running execution has completed
            if (!R.isNil(runningExecution.value) && !R.isNil(data.body.status)) {
                if (
                    data.executionId === runningExecution.value &&
                    ExecutionStatusWrapper.finishedStatuses().includes(data.body.status)
                ) {
                    modelValidationStatus.value = data.body.status;
                    modelValidationMessage.value = data.body.message;
                    canCloseModal.value = true;

                    unsubscribe(WebSocketsEvents.Workflow);
                    leaveSocketRoom(WebSocketsRoomTypes.Workflow, data.workflowId);
                }
            }
        }
    };

    const listenToWorkflow = (workflowId: string, executionId: string) => {
        runningExecution.value = executionId;
        subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        joinSocketRoom(WebSocketsRoomTypes.Workflow, workflowId);
    };

    return {
        asset,
        accessLevel,
        copyrightOwner,
        accessLevelOptions,
        customLicense,
        getLicensingSchema,
        initAsset,
        checkLicense,
        modelSourceOptions,
        modelTypeOptions,
        modelPurposeOptions,
        modelLibraryOptions,
        listenToWorkflow,
        showModelValidationModal,
        modelValidationStatus,
        canCloseModal,
        librarySelected,
        modalImage,
        modalTitle,
        modalDescription,
        modelStructure,
        getGeneralSchema,
        modelValidationMessage,
    };
}
