var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-2 gap-x-4 gap-y-6"},[_c('VerticalFormBlock',{attrs:{"title":"Time Period","description":"The time period for which the Quality Metrics will be displayed."}},[_c('div',{staticClass:"flex items-center mt-2"},[_c('ButtonGroup',{staticClass:"hidden h-10 xl:flex",attrs:{"keyField":"value","options":_vm.qualityMetricsFilters,"disabled":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var calculatedLabel = ref.calculatedLabel;
return [_c('span',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(calculatedLabel))])]}}]),model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}}),_c('TwSelect',{staticClass:"flex w-32 space-y-0 text-neutral-800 xl:hidden",attrs:{"items":_vm.qualityMetricsFilters,"canUnselect":false,"keyField":"value","disabled":_vm.loading,"rounded":"rounded-md","height":"h-10"},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}}),_c('div',{staticClass:"flex items-center ml-4 space-x-2"},[(_vm.dateFilter === 'range')?_c('v-date-picker',{attrs:{"mode":'datetime',"min-date":_vm.minStartDate,"max-date":_vm.maxEndDate,"masks":{ inputDateTime24hr: 'DD MMM YYYY HH:mm' },"model-config":{ timeAdjust: '00:00:00' },"timezone":"utc","isRequired":"","is24hr":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"w-full h-10 py-2 text-sm form-input",attrs:{"type":"text","placeholder":"From","disabled":_vm.loading},domProps:{"value":inputValue}},inputEvents))]}}],null,false,1790172605),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}):_vm._e(),(_vm.dateFilter === 'range')?_c('v-date-picker',{attrs:{"mode":'datetime',"min-date":_vm.startDate,"max-date":_vm.maxEndDate,"masks":{ inputDateTime24hr: 'DD MMM YYYY HH:mm' },"model-config":{ timeAdjust: '00:00:00' },"timezone":"utc","isRequired":"","is24hr":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"w-full h-10 py-2 text-sm form-input",attrs:{"type":"text","placeholder":"To","disabled":_vm.loading},domProps:{"value":inputValue}},inputEvents))]}}],null,false,1573488912),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}):_vm._e()],1)],1)]),_c('VerticalFormBlock',{attrs:{"title":"Granularity Level","description":"The basis (or level of detail) in which the time period will be displayed (in axis X in all diagrams)."}},[_c('ButtonGroup',{staticClass:"hidden h-10 mt-2 xl:flex",attrs:{"options":_vm.granularityOptions,"disabled":_vm.loading,"keyField":"value"},model:{value:(_vm.granularity),callback:function ($$v) {_vm.granularity=$$v},expression:"granularity"}}),_c('TwSelect',{staticClass:"w-32 mt-2 space-y-0 text-neutral-800 xl:hidden",attrs:{"items":_vm.granularityOptions,"canUnselect":false,"keyField":"value","disabled":_vm.loading,"rounded":"rounded-md","height":"h-10"},scopedSlots:_vm._u([{key:"tooltip",fn:function(ref){
var fullItem = ref.fullItem;
return [_vm._v(" "+_vm._s(fullItem.tooltip)+" ")]}}]),model:{value:(_vm.granularity),callback:function ($$v) {_vm.granularity=$$v},expression:"granularity"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }