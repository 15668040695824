

















import { defineComponent } from '@vue/composition-api';
import Scrollbar from '../Scrollbar.vue';
import TreeNode from './TreeNode.vue';

export default defineComponent({
    name: 'TreeView',
    props: {
        nodes: {
            type: Array,
            required: true,
        },
        selectedNode: {
            type: Object,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        additionalClasses: {
            type: [Array, String, Object],
            default: () => [],
        },
        expandable: {
            type: Boolean,
            default: true,
        },
        additionalScrollbarClasses: {
            type: [Array, String, Object],
            default: () => [],
        },
    },
    components: { TreeNode, Scrollbar },
    setup(props, { emit }) {
        const selectNode = (node: any) => {
            emit('selected', node);
        };

        return { selectNode };
    },
});
