

































































































































































import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { DescriptionListCard, TimelinessBadge, VerticalFormBlock, WeatherDynamicIcon } from '@/app/components';

import { AssetType, StatusCode } from '../constants';
import { useFilters } from '@/app/composable/filters';
import { ArrowUpIcon, XCircleIcon } from '@vue-hero-icons/solid';
import CompletenessGraph from './CompletenessGraph.vue';
import TimelinessGraph from './TimelinessGraph.vue';
import ReliabilityGraph from './ReliabilityGraph.vue';
import GraphFilters from './GraphFilters.vue';
import { Granularity } from '../types/granularity.type';
import { useAsset } from '../composable/asset';
import { useAxios } from '@/app/composable';
import { MonitoringAPI } from '@/app/api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'ramda';
import { useSampleFields } from '@/modules/apollo/composable';
import { MappingFieldConfiguration } from '@/modules/apollo/types/typings';
import { AnonymisationFieldType } from '@/modules/apollo/types';

dayjs.extend(utc);

export default defineComponent({
    name: 'QualityTab',
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    components: {
        DescriptionListCard,
        TimelinessBadge,
        VerticalFormBlock,
        WeatherDynamicIcon,
        CompletenessGraph,
        TimelinessGraph,
        ReliabilityGraph,
        ArrowUpIcon,
        GraphFilters,
        SelfBuildingSquareSpinner,
        XCircleIcon,
    },
    setup(props) {
        const { formatDecimals } = useFilters();
        const { assetTypeName } = useAsset();
        const { exec, loading: loadingMetrics, error: errorInMetrics } = useAxios(true);
        const { extractMappingFieldNames } = useSampleFields();

        const assetMetrics: Ref<
            | {
                  qualityImprovement?: number;
                  completeness: number;
                  timeliness: string;
                  reliability: number;
                  informationLoss?: {
                      informationLoss: number;
                      fields: { identifier: string; informationLoss: number }[];
                  };
              }
            | undefined
        > = ref();

        const assetType: Ref<AssetType> = computed(() => {
            if (props.asset.assetTypeId) return assetTypeName(props.asset.assetTypeId);
            return AssetType.Dataset;
        });
        const reliabilityTooltip: Ref<string> = computed(() => {
            if (assetType.value === AssetType.Dataset)
                return `Reliability is the percentage of successful executions that added data to the dataset, compared to the total (expected) executions of the respective data check-in pipeline.`;
            return `Reliability is the percentage of successful executions that added data to the result, compared to the total executions of the respective data analytics pipeline.`;
        });

        const extractedMappedFields = computed(() =>
            extractMappingFieldNames(props.asset.processingRules.mappingRules),
        );

        const anonymisationFields = computed(() =>
            props.asset.processingRules.anonymisationRules.reduce(
                (
                    acc: Record<string, MappingFieldConfiguration & AnonymisationFieldType>,
                    field: AnonymisationFieldType,
                ) => {
                    const mappedField = extractedMappedFields.value.find(
                        (m: MappingFieldConfiguration) => m.name === field.name,
                    );
                    if (mappedField)
                        acc[field.anonymisationIdentifier] = {
                            ...mappedField,
                            ...field,
                        };
                    return acc;
                },
                {},
            ),
        );
        const informationLossPerField = computed(() =>
            assetMetrics.value?.informationLoss
                ? assetMetrics.value.informationLoss.fields.map(
                      (field: { identifier: string; informationLoss: number }) => {
                          console.log(anonymisationFields.value[field.identifier]);
                          return {
                              ...field,
                              title: anonymisationFields.value[field.identifier]?.title || field.identifier,
                              alias: anonymisationFields.value[field.identifier]?.alias,
                          };
                      },
                  )
                : [],
        );

        const metricsQuery: Ref<
            | {
                  granularity: Granularity;
                  startDate: Date | undefined;
                  endDate: Date | undefined;
              }
            | undefined
        > = ref<
            | {
                  granularity: Granularity;
                  startDate: Date | undefined;
                  endDate: Date | undefined;
              }
            | undefined
        >();

        if (AssetType.Dataset === assetType.value && props.asset.status === StatusCode.Available)
            exec(MonitoringAPI.datasetMetrics(props.asset.id)).then((res: any) => {
                if (res) assetMetrics.value = res.data;
            });

        if (AssetType.Result === assetType.value && props.asset.status === StatusCode.Available)
            exec(MonitoringAPI.resultMetrics(props.asset.id)).then((res: any) => {
                if (res) assetMetrics.value = res.data;
            });

        return {
            reliabilityTooltip,
            errorInMetrics,
            loadingMetrics,
            assetMetrics,
            metricsQuery,
            formatDecimals,
            assetType,
            AssetType,
            informationLossPerField,
            isNil,
        };
    },
});
