





















































































import { defineComponent, computed, ref, inject } from '@vue/composition-api';
import Draggable from 'vuedraggable';
import ShortTypeBadge from '../ShortTypeBadge.vue';

const TreeNode = defineComponent({
    name: 'TreeNode',
    props: {
        node: {
            type: Object,
            required: true,
        },
        selected: {
            type: Object,
            required: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expandable: {
            type: Boolean,
            default: true,
        },
    },
    components: { Draggable, ShortTypeBadge },
    setup(props, { emit }) {
        const dragging = inject('dragging', ref(false));
        const hasChildren = computed(() => props.node.children && props.node.children.length > 0);
        const isOpen = ref<boolean>(props.expanded);
        const isSelected = computed(() => props.selected === props.node);
        const selectNode = (selected: any) => {
            emit('selected', isSelected.value ? null : selected);
        };
        const open = () => {
            if (props.expandable) {
                if (hasChildren.value) {
                    isOpen.value = !isOpen.value;
                    selectNode(null);
                } else {
                    selectNode(props.node);
                }
            }
        };

        return { selectNode, hasChildren, isOpen, isSelected, open, dragging };
    },
});

export default TreeNode;
